import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import '../../scss/components/preview.scss';

export default function Preview() {

  return (
      <Parallax opacity={[2, -0.1]}>
          <section className="preview">
              <div className="preview__content">
                  <Parallax translateY={[0, -100]} opacity={[2, 0]}>
                      <div className="preview__img">
                          <img src="/images/preview/logoBig.png" alt="logo" />
                      </div>
                  </Parallax>
                  <Parallax translateY={[0, -340]} opacity={[1.4, 0]}>
                      <h2 className="preview__title">
                          FIRST PLAY AND EARN PROJECT FOR GAMERS
                      </h2>
                  </Parallax>
              </div>
          </section>
      </Parallax>
  );
}
