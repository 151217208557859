import React from 'react';
import '../../scss/components/video.scss'
import {Parallax} from "react-scroll-parallax";

export default function Video() {
  return (
      <Parallax opacity={[2, -0.5]} translateY={[0, -20]}>
          <section className='video'>
              <div className="video__content">
                  <img src="/images/video/video.png" alt="video" />
              </div>
          </section>
      </Parallax>
  )
}
