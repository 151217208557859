import React from 'react';
import '../../scss/components/little.scss';
import {Parallax} from "react-scroll-parallax";



export default function Little() {
  return (
      <Parallax opacity={[1.5,0]}>
        <section className='little'>
                <div className="little__row">
                    <div className="little__item little__item-left active">
                      <img src="/images/little/img2.png" alt="battle" />
                    </div>
                    <div className="little__item little__item-center">
                      <img src="/images/little/img3.png" alt="battle" />
                    </div>
                    <div className="little__item little__item-right">
                      <img src="/images/little/img1.png" alt="battle" />
                    </div>
                </div>
                <h1 className="little__title title-gold">
                WHAT WILL HELP YOU TO WIN IN BATTLES?
                </h1>
        </section>
      </Parallax>
  )
}
