import React from "react";
import "../../scss/components/why.scss";
import {Parallax} from "react-scroll-parallax";



export default function Why() {
  return (
      <Parallax opacity={[2, -0.3]}>
        <section className="why">
          <div className="why__container">
            <Parallax translateY={[0, -40]}>
              <h1 className="why__title title-gold">
                WHY YOU SHOULD PLAY VOID GODS?
              </h1>
            </Parallax>
            <div className="why__content">
              <div className="why__content-left">
                <div className="why__reason">

                    <div className="lable" data-aos="fade-right" />

                  <p> No crypto wallet needed until money withdraw;</p>
                </div>
                <br />
                <div className="why__reason">

                    <div className="lable" data-aos="fade-right"/>

                  <p>
                    {" "}
                    Storydriven gameplay in epic global plot each player can
                    influence.
                    There are no grind or boring activities;
                  </p>
                </div>
                <div className="why__reason">


                    <div className="lable" data-aos="fade-right"/>


                  <p> Cinematic experience with highquality AAA graphics;</p>
                </div>
              </div>
              <div className="why__content-right">
                <div className="why__reason">

                    <div className="lable" data-aos="fade-left"/>


                  <p>
                    {" "}
                    Social relationships. Each player has an ability to get vassals;
                  </p>
                </div>
                <div className="why__reason">

                    <div className="lable" data-aos="fade-left" />

                  <p>
                    {" "}
                    NFT ownership. Each batle robot, pilot, weapon or even planetare your's. You are able to sell it in any time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Parallax>
  );
}
