import React from 'react';
import '../../scss/components/pilots.scss';
import {Parallax} from "react-scroll-parallax";




export default function Pilots() {
  return (
      <Parallax opacity={[2.3, -0.3]} translateY={[0, -20]}>
          <section className="pilots">
              <h1 className="pilots__title title-purpl">
                  EXPERIENCED PILOTS
              </h1>
              <div className="pilots__content">
                    <Parallax
                        translateX={['-200px', '0px']}
                        rotate={[-12, 0]}
                        easing="ease"
                    >
                        <div className="pilots__one">
                            <img src="/images/pilots/femaleLegendary.png" alt="pilot" />
                        </div>
                    </Parallax>

                  <div className="pilots__text">
                      Battlemechs are extremely rare. However, people are able to control that monsters are much more infrequent.
                      <br/><br/>
                      There are no 2 equal pilots in the galaxy. Each of them has unique set of skills. Combine them to achieve the most effective<br/>
                      synergy.
                      <br/><br/>
                      Pilots are divided by their experience. The most skilled are well-known like rock stars.
                  </div>
                  <Parallax
                      translateX={['200px', '0px']}
                      rotate={[12, 0]}
                      easing="ease"
                  >
                      <div className="pilots__two">
                          <img src="/images/pilots/maleLegendary.png" alt="pilot" />
                      </div>
                  </Parallax>
              </div>
          </section>
      </Parallax>
  )
}
