import {
  Battlemech,
  Discord,
  Factions,
  Footer,
  Header,
  HowEarn,
  Little,
  Origin,
  Pilots,
  Planets,
  Preview,
  Roadmap,
  Video,
  Weapons,
  Why,
} from "./components";
import "./scss/index.scss";
import {ParallaxProvider} from "react-scroll-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import {useEffect} from "react";

function App() {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

  return (
    <div className="app">
        <ParallaxProvider>
            <Header />
            <Preview />
            <Why />
            <Video />
            <HowEarn/>
            <Little/>
            <Battlemech/>
            <Pilots/>
            <Weapons/>
            <Planets/>
            <Roadmap/>
            <Origin/>
            <Factions/>
            <Discord/>
            <Footer/>
        </ParallaxProvider>
    </div>
  );
}

export default App;
